.signin-link-container {
  float: right;
}

.signin-link {
  font-size: 1rem;
  font-weight: 600;
  background-color: #f5e43f;
  text-decoration: none;
  padding: 1em 1.5em 1em 1.5em;
  margin-left: 1em;
}

.signin-link:hover,
.signin-link:focus {
  opacity: .7;
}


.signup-form {
  font-size: 1.5rem;
  width: 90%;
  margin: 0 auto 2em auto;
}

.signup-form a {
  color: black;
}
@media (min-width: 900px) {
  .signup-form {
    font-size: 1.5rem;
    width: 50%;
    margin: 0 auto;
  }
  
}


.signin-form {
  font-size: 1.5rem;
  width: 90%;
  margin: 0 auto 2em auto;
}

.signin-form a {
  color: black;
}
@media (min-width: 900px) {
  .signin-form {
    font-size: 1.5rem;
    width: 50%;
    margin: 0 auto;
  }
  
}

.signinup-container {
  float: right;
}
