.main-container {
  width: 100%;
  /* margin: 3em auto; */
}

.host-profile-container {
  padding: 0 0 2rem 0;
  color: black;
}


.error-container {
  background-color: rgb(190, 48, 48);
  color: white;
  border-radius: 5px; 
  padding: 2rem;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.map-static {
  width: 100%;
  margin-bottom: 2em;
}

.host-btn-container {
  margin-bottom: 2em;
}

.error-container-hide {
  display: none;
}

.host-no-data-container {
  width: 100%;
  margin: 0 auto;
}

.host-no-data-message {
  font-size: 2rem;
}

.host-btn {
  width: 10em;
  border: none;
  color: black;
  background-color: #f5e43f;
  padding: 1.5em;
  font-weight: 600;
}


.host-btn:hover,
.host-btn:focus {
  opacity: .7; 
}

.float-right {
  float: right;
}

.host-header-container {
  width: 100%;
  margin: 0 auto;
}

.host-header {
  font-size: 3rem;
}

.host-greeting {
  font-size: 2rem;
  margin-bottom: .5em;
}

.host-edit-btn {
  margin-right: 2em;
  font-weight: 600;
}

.spacer-right {
  margin-right: 3em;
}
/* view */

/* .host-view-container {
  border: 1px solid red;
} */

.host-view-item {
  display: flex;
  flex-direction: column;
  margin-bottom: .5em;
  font-size: 1.25em;  
}

.host-view-item-label {
  width: 100%;
  margin-bottom: .25em;
  font-weight: 500;
}

.host-view-item-value {
  margin-bottom: .5em;
  
}

/* edit */

.host-edit-container {
  width: 100%;
}  

.host-edit-item {
  display: flex;
  flex-direction: column;
  margin: 0 0 .75em 0;
  font-size: 1.25em;  
}

.host-edit-item-label {
  width: 100%;
  margin-bottom: .25em;
}

.host-edit-item-input {
  line-height: 1.5em;
}

.host-edit-item-textarea{
  width: 99%;
  height: 6em; 
}

.host-edit-item-select{
  width: 6em; 
}

@media (min-width: 550px) and (max-width: 799px) {
  .host-no-data-container {
    width: 80%;
    font-size: 3rem;
  }
  
  .host-header-container {
    width: 80%;
  }

  .host-edit-container {
    width: 80%;
    margin: 0 auto;
  }

  .host-view-container {
    width: 80%;
    margin: 0 auto;
  }
  
}
@media (min-width: 800px) {
  .host-no-data-container {
    width: 50%;
    margin: 0 auto;
  }
  
  .host-header-container {
    width: 50%;
  }

  .host-edit-container {
    width: 50%;
    margin: 0 auto;
  }

  .host-view-container {
    width: 50%;
    margin: 0 auto;
  }
  
}

.host-edit-church-photo {
  width: 20rem;
}
.host-view-church-photo {
  width: 20rem;
}