.signin-item {
  display:flex;
  flex-direction: column;
  margin-bottom: 2em;
}

.error-container-hide {
  display: none;
}

.email-verification-container {
  margin: auto;
  width: 100%;
}

@media (min-width: 900px) {
  .email-verification-container {
    margin: auto;
    width: 60%;
  }
}