.join-text {
  font-size: 1.5rem;
  width: 90%;
  margin: 0 auto 2em auto;
}

.join-text a {
  color: black;
}
@media (min-width: 900px) {
  .join-text {
    font-size: 1.5rem;
    width: 50%;
    margin: 0 auto;
  }
  
}
